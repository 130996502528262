@import './common/reset.css';

body {
  background: #DAE0E2;
  padding: 16px;
  font-family: Arial, Helvetica, sans-serif;
}

.title {
  text-align: center;
  font: normal 24px arial, helvetica, sans-serif;
  color: #0A3D62;
}
